body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
    color: #000;
}

header {
    margin: 20px auto;
    max-width: 986px;
    overflow: hidden;
}

.language-navigation {
    border-bottom: 1px solid #000;
    overflow: hidden;
    padding: 0 0 16px;
    width: 100%;
}

.language-navigation select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url(../images/language-icon-48.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 0;
    cursor: pointer;
    direction: rtl;
    height: 24px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 20px;
    float: right;
    outline: none;
    text-align-last: right;
    text-indent: -9999px;
    width: 24px;
}

h1 {
    float: left;
    font-size: 25px;
    font-weight: normal;
    line-height: 25px;
    margin: 16px 0;
    width: 35%;
}

h1 a {
    text-decoration: none;
}

h1 a:focus,
h1 a:visited {
    color: inherit;
}

.hamburger {
    display: none;
}

.menu-navigation {
    float: left;
    width: 65%;
}

.menu-navigation ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 16px 0;
    padding: 0;
}

.menu-navigation ul li {
    font-size: 20px;
    font-weight: normal;
    line-height: 25px;
}

.menu-navigation ul li a {
    text-decoration: none;
}

.menu-navigation ul li a:focus,
.menu-navigation ul li a:visited {
    color: inherit;
}

section {
    margin: 0 auto;
    max-width: 986px;
}

img {
    width: 100%;
}

.justified-text {
    font-size: 16px;
    margin: 1em auto;
    max-width: 680px;
}

.centered-text {
    font-size: 16px;
    margin: 1em auto;
    max-width: 680px;
    text-align: center;
}

h2 {
    border-top: 1px solid #000;
    font-size: 25px;
    font-weight: normal;
    margin: 0 0 16px;
    padding: 8px 0 0;
}

h2 a {
    text-decoration: none;
}

h2 a:focus,
h2 a:visited {
    color: inherit;
}

h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 1em auto 0;
    max-width: 680px;
}

h3 ~ .justified-text {
    margin: 0 auto 1em;
}

.button-container {
    display: flex;
    justify-content: center;
}

.round-button-2 {
    background-color: rgb(51, 48, 186);
}

.round-button-1 {
    background-color: rgb(128, 128, 128);
}

.round-button {
    border: 2px solid #000;
    border-radius: 65.5px;
    cursor: pointer;
    display: block;
    font-size: 16px;
    height: 123px;
    line-height: 123px;
    margin: 1em auto;
    outline: none;
    padding: 0;
    position: relative;
    color: #eeeeee;
    text-align: center;
    text-decoration: none;
    width: 123px;
}

.round-button::before {
    border: 1px solid #000;
    border-radius: 71px;
    content: '';
    display: block;
    height: 140px;
    position: absolute;
    right: -9.5px;
    top: -9.5px;
    width: 140px;
}

.round-button:hover {
    border-radius: 64.5px;
    height: 121px;
    margin: calc(1em + 1px) auto;
    width: 121px;
}

.round-button:hover::before {
    right: -10.5px;
    top: -10.5px;
}

.round-button:active {
    border-radius: 64.5px;
    height: 121px;
    margin: calc(1em + 2px) auto 1em;
    width: 121px;
}

.round-button:active::before {
    right: -10.5px;
    top: -11.5px;
}

.colour-namer-wrapper {
    background-color: #808080;
}

.colour-namer-row-wrapper {
    margin: 0 auto;
    max-width: 430px;
    padding: 10px;
    overflow: hidden;
}

.colour-namer-row-wrapper select {
    background-color: #FFF;
    border: none;
    display: block;
    height: 30px;
    width: 95px;
}

.left-aligned-select {
    float: left;
}

.right-aligned-select {
    float: right;
}

#colour-picker {
    float: left;
}

#colour-picker-right {
    float: right;
    width: 193px;
}

.stats-box {
    border: 1px solid black;
}

.stats-box-header {
    background-color: #FF0000;
    border-bottom: 1px solid black;
    height: 60px;
    width: 100%;
}

.stats-box dl {
    color: white;
    margin: 1em 0.5em 0.5em;
    overflow: hidden;
    padding: 0;
}

.stats-box dt {
    clear: both;
    float: left;
}

.stats-box dd {
    clear: both;
    float: right;
}

.stats-box ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.stats-box li {
    text-align: right;
}

#agreement {
    clear: both;
    margin: 10px 0 0;
    width: 195px;
}

.padded {
    font-size: 16px;
    margin: 1em auto;
    max-width: 680px;
    padding: 0 0 0 2em;
}

.emerald li,
.emerald li a {
    color: #50C878;
}

.ruby li,
.ruby li a {
    color: #9B111E;
}

.sapphire li,
.sapphire li a {
    color: #0F52BA;
}

form {
    margin: 1em auto 2.5em;
    max-width: 680px;
    padding: 0 0 25px;
    position: relative;
}

label {
    font-size: 16px;
}

input {
    border: 1px solid #808080;
    border-radius: 5px;
    display: block;
    font-size: 16px;
    height: 25px;
    outline: none;
    padding: 1px 1em;
    margin: 0.5em -1px;
    width: calc(100% - 2em);
}

textarea {
    border: 1px solid #808080;
    border-radius: 5px;
    display: block;
    font-size: 16px;
    outline: none;
    padding: 0.5em 1em;
    margin: 0.5em -1px;
    width: calc(100% - 2em);
}

.submit-button {
    background-color: #808080;
    border: none;
    border-radius: 5px;
    color: #FFF;
    cursor: pointer;
    font-size: 16px;
    height: 30px;
    margin: 0.5em -1px;
    outline: none;
    padding: 2px 20px;
    position: absolute;
    right: 0;
}

.submit-button:hover {
    height: 28px;
    margin: calc(0.5em + 1px) 0;
    padding: 1px 19px;
}

.submit-button:active {
    height: 28px;
    margin: calc(0.5em + 2px) 0 0.5em;
    padding: 1px 19px;
}

footer {
    margin: 20px auto;
    max-width: 986px;
}

.social-links {
    margin: 0 auto;
    overflow: hidden;
    width: 240px;
}

.social-link {
    background-color: #808080;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    border-radius: 20px;
    display: block;
    float: left;
    height: 40px;
    margin: 3px 10px 5px;
    position: relative;
    width: 40px;
}

.social-link::before {
    border: 1px solid #808080;
    border-radius: 22px;
    content: '';
    display: block;
    height: 44px;
    position: absolute;
    right: -3px;
    top: -3px;
    width: 44px;
}

.social-link:hover {
    background-size: 28px;
    height: 38px;
    margin: 4px 11px 4px;
    width: 38px;
}

.social-link:hover::before {
    right: -4px;
    top: -4px;
}

.social-link:active {
    background-size: 28px;
    height: 38px;
    margin: 5px 11px 3px;
    width: 38px;
}

.social-link:active::before {
    right: -4px;
    top: -4px;
}

.social-link.email {
    background-image: url(../images/email.png);
}

.social-link.facebook {
    background-image: url(../images/facebook.png);
}

.social-link.github {
    background-image: url(../images/github.png);
}

.social-link.twitter {
    background-image: url(../images/twitter.png);
}

.lined-text {
    border-top: 1px solid #000;
    font-size: 16px;
    margin: 1em auto;
    padding: 10px 0;
    text-align: center;
}

@media (max-width: 600px) {

    .language-navigation select {
        background-image: url(../images/language-icon-96.png);
        height: 48px;
        width: 48px;
    }

    h1 {
        float: left;
        width: calc(100% - 49px);
    }

    .hamburger {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: block;
        float: left;
        height: 30px;
        margin: 14px 0;
        outline: none;
        position: relative;
        text-indent: -9999px;
        width: 49px;
    }

    .hamburger::after,
    .hamburger::before {
        content: '';
    }

    .hamburger::after,
    .hamburger::before,
    .hamburger span {
        background-color: #000;
        height: 2px;
        left: 0;
        position: absolute;
        transition: top 0.3s ease-out 0.3s, transform 0.3s ease-in;
        width: 49px;
    }

    .hamburger::after {
        top: 30px;
    }

    .hamburger::before {
        top: 0;
    }

    .hamburger span {
        top: 15px;
    }

    header.revealed .hamburger::after,
    header.revealed .hamburger::before,
    header.revealed .hamburger span {
        transition: top 0.3s ease-in, transform 0.3s ease-out 0.3s;
    }

    header.revealed .hamburger::after,
    header.revealed .hamburger::before {
        top: 15px;
        transform: rotate(45deg);
    }

    header.revealed .hamburger span {
        transform: rotate(-45deg);
    }

    .menu-navigation {
        float: none;
        width: 100%;
    }

    .menu-navigation ul {
        display: none;
    }

    header.revealed .menu-navigation ul {
        display: block;
    }

    .menu-navigation ul li {
        padding: 0 0 10px;
    }

    .menu-navigation ul li:last-child {
        padding: 0;
    }

}

@media (max-width: 470px) {

    .colour-namer-row-wrapper {
        max-width: 195px;
    }

    #colour-picker {
        margin: 0 0 20px;
    }

}
